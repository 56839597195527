body{
  color: black;
}
.homeScreen{
  background-color: black;
  text-align: center;
  height:900px;
}



.myProfileBackground{
  background-color: #118ab2;
}

.header{
  display:flex;
  background-color:black;
}

.myProfile{
  height: 400px;
  max-height: 400px;
  margin-bottom: 50px;
  background-color: hsla(200,40%,30%,.4);
  background-image:       
      url('https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png'), 
      url('https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png'),
      url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
      url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),
      url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png');
  background-repeat: repeat-x;
  background-position: 
      0 20%,
      0 100%,
      0 50%,
      0 100%,
      0 0;
  background-size: 
      2500px,
      800px,
      500px 200px,
      1000px,
      400px 260px;
  animation: 50s para infinite linear;
  }

@keyframes para {
  100% {
      background-position: 
          -5000px 20%,
          -800px 95%,
          500px 50%,
          1000px 100%,
          400px 0;
      }
  }



.nameHeader{
  font-family: 'Bangers', cursive;
  text-align: left;
  color: white;
  font-size: 80px;
  width: 50%;
  margin-left: 20px;
  text-shadow: 2px 2px black;
  margin-bottom: -1px;
}

.navBar{
  font-size: 20px;
  text-align: right;
  width: 50%;
  padding-bottom: 10px;
  padding-top: 25px;
  margin-right: 20px;
}



.navBarLink{
text-decoration: none;
display: inline;
color: white;
font-size: 23px;
margin: 10px;
padding-top: 10px;

}

.navBarLink a {
  text-decoration: none;
  display: inline;
  color: white;
  position: relative;
  }

  .navBarLink a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #06d6a0;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.5s ease-in-out 0s;
    }

    .navBarLink a:hover::before {
      visibility: visible;
      transform: scaleX(1);
      }


.navBarLink a:visited{
color: white
}

.navBarLink a:hover{
  text-decoration: none;
  display: inline;
  /* color: #06d6a0; */
  }


#githubIcon{
  color: #06d6a0;
  opacity: .8;
}

#githubIcon:hover{
  opacity: 1;
}

#mediumIcon{
  color: #ef476f;
  opacity: .8;
}

#mediumIcon:hover{
  opacity: 1;
}

#linkedInIcon{
  color:#118ab2;
  opacity: .8;
}

#linkedInIcon:hover{
  opacity: 1;
}

#youTubeIcon{
  color:#ffd166;
  opacity: .8;
}

#youTubeIcon:hover{
  opacity: 1;
}


#nameProfileHeader{
  color:white;
  font-size: 40px;
  border-bottom: 2px solid white;
  margin-right: 400px;
  margin-left: 400px;
  text-shadow: 2px 2px black;
  animation: bounceIn 2s;
}

#bioProfileHeader{
  color:white;
  padding-bottom: 10px;
  text-shadow: 2px 2px black;
  font-size:70px;
  animation: bounceIn 2s;
  padding-top: 50px;
}

.infoBody{
  /* display: flex; */
  height: 600px;
}


.carouselContainer{
  height: 300px;
  display: flex;
  background-color: black;

}

.imageCarousel{
  width: 50%; 
  height:cover;
}
.carousel .slide{
  height: 350px;
}

.interestsCarousel{
  width: 50%; 
  height:100%;
}

#selfieImg{
  width: 100%;
  height: 100%;
  max-height: 350px;
  object-fit: cover;
}

.officeImgs{
  height:500px;
}

.aboutMe{
  width: 100%;
  padding-top: 90px;
  margin-bottom: 50px;
  color:white;
  font-weight: bold;
  background-color: black;
}

.aboutMeParagraph{
  text-align: center;
  font-size: 20px;
  font-family: 'Bungee Inline', cursive;
  width: 70%;
  margin: auto;
}


.interests{
  max-width: 1100px;
  margin: auto;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
  background-color: black;
  color:white;
  font-weight: bold;
  text-align: center;
}

.interests-left{
  width:50%;
}

.interests-right{
  width:50%;
}

#interestTitle{
  font-family: 'Bungee Shade', cursive;
  font-size: 30px;
  color: #06d6a0;
}

#educationTitle{
  font-family: 'Bungee Shade', cursive;
  font-size: 30px;
  color: #06d6a0;
}

.interestList{ 
  font-family: 'Bungee Inline', cursive;
  text-align: center;
  font-size: 15px;
  list-style-type: none;
}
.interestList li{
  margin: 5px

}

.disabledIcon{
  pointer-events: none;
}

.contactMe{
  color:white;
  padding-bottom: 20px;;
  margin-top: 50px;
  height:300px;
  background-color: #06d6a0;
}

#contactMeTitle{
  padding-top: 20px;
  padding-bottom: 50px;
  font-size: 50px;
  text-shadow: 2px 2px black;
  color:white;
  font-family: 'Bungee Shade', cursive;
}

#contact-form-disclaimer{
  padding-top: 10px;
  font-size: 12px;
}

#contact-form-disclaimer-green-text{
  color:green;
}


#projectTitle{
  padding-top: 50px;
  color: white;
  font-size: 40px;
  text-shadow: 1px 1px #3c6e71;
  font-family: 'Bungee Shade', cursive;
  transform: skewY(11deg);

}

#youtubeDesc{
font-family: 'Bungee Inline', cursive;
text-shadow: 1px 1px black;
color:white;
font-size: 20px;
padding-bottom: 30px;
transform: skewY(11deg);
}



.videoContainer{
  padding-top: 10px;
  width:600px;
  height:400px;
  margin: auto;
  transform: skewY(11deg);
}

.Footer{
  padding-top: 30px;
  width: 100%;
  color:  white;
  background-color: black;
  text-align: center;
  margin-right: 10px;
  text-shadow: 2px 2px black;
}
.scrollHelper{
  transform: skewY(11deg);
}
.currentProject{
  background-color: #118ab2;
  transform: skewY(-11deg);
  height: 650px;
  margin-top: 100px;
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 10px rgb,a(0, 0, 0, 0.4) 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.rubberband{
  transform: skewY(11deg);
}
.currentProjectTitle{
  font-family: 'Bungee Shade', cursive;
  font-size: 40px;
  transform: skewY(11deg);
  padding-top: 100px;
  margin-left: 200px;
  margin-right: 200px;
  padding-bottom: 5px;
  color:white;
  text-shadow: 1px 1px black;
}

.currentProjectDesc{
  font-family: 'Bungee Inline', cursive;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-left: 100px;
  margin-right: 100px;
  padding-bottom: 30px;
  text-shadow: 1px 1px black;
}

.deployedAppsContainer{
  width:800px;
  margin: auto;
}

.deployedApps{
  height:400px;
  width: 100%;
  background-color: white;
  margin-bottom: 20px;
}

.deployedAppsTitle{
  font-family: 'Bungee Shade', cursive;
  padding-top: 150px;
  color: white;
  font-size: 40px;
  text-shadow: 1px 1px #3c6e71;
  
}

.deployedApp:hover {
    filter: brightness(120%)
  }


.deployedAppImg{
  height: 300px;
  width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}





.deployedAppsDesc{
  color:white;
  text-shadow: 2px 2px black;
  padding-bottom: 50px;
  font-size: 20px;
  font-family: 'Bungee Inline', cursive;

}
.deployedAppDesc{
  font-family: 'Bungee Inline', cursive;

}

#wtwTitle{
  font-family: 'Bungee Outline', cursive;
  padding-top: 20px;
  font-size: 35px;
  color: #118ab2;

}

#if2Title{
  font-family: 'Bungee Outline', cursive;
  padding-top: 20px;
  color: #ffd166;
  font-size: 35px;

}

#ccTitle{
  font-family: 'Bungee Outline', cursive;
  padding-top: 20px;
  color: #ef476f;
  font-size: 35px;
}

.deployedAppDesc{
  color:white;
  font-size: 20px;
  padding-bottom: 60px;
}

#projectsHeaderImageContainer{
  display: flex;
  align-items: center;
  padding-top: 100px;
  background-color: white;
}

#projectsHeaderImage{
  display: block;
  margin: auto;
}

.currentNumbersContainer{
  display: flex;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
  padding-top: 50px;

}
.projectScreen{
  background-color: white;
}
.currentNumber{
  margin: auto;
  text-align: center;
  width: 25%;
}

.currentNumberTitle{
  margin-bottom: 30px;
  font-size: 25px;
  font-family: 'Bangers', cursive;
}

.countingNumber{
  font-size: 50px;
  color:#118ab2;
  text-shadow: none;
  font-family: 'Bangers', cursive;
}

.currentNumberIcon{
  color: black;
  pointer-events: none
}

#githubSquareIcon{
  color: #9b5de5
}

#githubAlternateIcon{
  color: #f15bb5
}

#folderIcon{
  color: #fee440
}

#coffeeIcon{
  color: #b2967d
}


.screenTitle{
  text-align: center;
  font-family: 'Bungee Shade', cursive;
  font-size: 70px;
  color:#06d6a0;
  padding-bottom: 20px;
}

.socialMediaNavBarContainer{
  height: 165px;
}

.projectContainer{
  text-align: center;
  font-size: 15px;
  font-family: 'Bungee Inline', cursive;
}

.projectContainerFlexbox{
  display: flex;
  width: 80%;
  justify-content: center;
  margin: auto
}

.projectContainerTitle{
 padding-top: 20px;
 padding-bottom: 20px;
 font-family: 'Bungee Shade', cursive;
 font-size: 35px;
}

.projectContainerHeader{
  font-family: 'Bungee Inline', cursive;
  font-size: 25px;
}

.projectImageSS{
  width: 90%;
  height: 300px;
  display: block;
  margin: auto;
  box-shadow: 2px 2px 11px black;

}

.projectImageSSContainer{
  width: 50%; 

}

.projectDescriptionContainer{
  width: 50%;
  text-align: center;
}

.accessContainer{
  text-align: left;
  margin: auto;
  width:120px;
}


.technologiesList{
  margin-bottom: 20px;
  pointer-events: none;
}

.technologiesList li{
  display: inline;
  position: relative;
  margin: 15px;
  font-size: 15px;
}

.projectContainerDescription{
  text-align: left;
}

#blogHeaderImage{
  width: 60%;
  height: 300px;
  display: block;
  margin: auto;
  border-radius: 10px;
}

#blog-loader-title{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 80px;
}

#blogHeaderImageContainer{
  background-color: white;
}

.blogs-container{
  height: 100%;
  width:100%;
  background-color: white;
  padding-bottom: 50px;
}

.blog-card{
  width:80%;
  margin: auto;
  display: flex;
  margin-bottom: 50px;
}
.blog-card-image{
  width:100%;
  height: 100%;
  border-radius: 10px;
}

.blog-card-left{
  width:50%;
}

.blog-card-right{
  width:50%;
  margin-left: 40px;
}
.blog-card-title{
  font-family: 'Bungee Inline', cursive;
}

.blog-card-description{
  
}

.blog-card-bold-text{
  font-weight: bold;
}

.blog-view-all-container{
  display: flex;
  justify-content: center;
}

/* .blog-flexbox-container{
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
}
.blog-card{
  box-shadow: 2px 2px 11px black;
  margin: 15px;
  width: 30%;
  height: 300px;
 
} */

.aboutScreen{
  height: 400px;
  background-color: black;
}

.aboutScreen-img-container{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  }

  #about-me-img{
    border-radius: 10px;
    }

.contactScreen{
  background-color: black;
  height:900px;
  text-align: center;
}

#contactLink{
color:white;
opacity: .8;
}

#contactLink:hover{
  opacity: 1;
  }



.contact-form-card{
  width: 300px;
  padding: 15px;
  margin:auto;
  border-radius: 10px;
  background-color: white;
}

.contact-form-container{
  /* background-image: url(./images/contactHeader.svg);
  background-position: center;
  background-repeat: no-repeat; */
  height:500px;
  margin:auto;
  display:block;
  margin-bottom:50px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height:400px;
}

.contact-form-container-particles{
  position: absolute;
  width:90%;

}

.contact-form-container-info{
z-index: 1;
}

#tsparticles{
  width:100%;
  height: 500px
}

.project-chart-container{
  margin: auto;
  width: 90%;
}

#users-chart{

}

@media only screen and (max-width: 1000px) {

.blog-card{
  width:80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  
}
.blog-card-image{
  width:100%;
  border-radius: 10px;
}

.blog-card-left{
  width:100%;
  padding-bottom: 20px;
}

.blog-card-right{
  width:100%;
  margin-left: 0px;
}

}

@media only screen and (max-width: 750px) {
  .header{
    padding-top: 10px;
    display: block;
    padding-bottom: 25px;
  }



/* 
.navBar{
  font-size: 20px;
  text-align: right;
  width: 50%;
  padding-bottom: 10px;
  padding-top: 25px;
  margin-right: 20px;
} */

/* 
.navBarLink{
  text-decoration: none;
  display: inline;
  color: white;
  font-size: 23px;
  margin: 10px;
  padding-top: 10px;
  
  } */
  .navBar{
    text-align: center;
    width:100%;
    margin-left: -10px;
    margin-bottom: 10px;
  }
  .navBarList{
    display:inline;
  }

  .navBarLink{
    font-size: 17px;
    margin:5px;
    padding-bottom: 10px;
  }
  


  #nameProfileHeader{
    color:white;
    font-size: 40px;
    border-bottom: 2px solid white;
    margin-right: 1px;
    margin-left: 1px;
    text-shadow: 2px 2px black;
  
  }

  .nameHeader{
    text-align: center;
    color: white;
    font-size: 50px;
    width: 100%;
    margin-left: -2px;
    text-shadow: 2px 2px black;
  }
  
  #bioProfileHeader{
    color:white;
    font-size: 30px;
    padding-bottom: 10px;
    text-shadow: 2px 2px black;
    margin-top: -30px;
  }



.carousel .slide{
  max-height: 300px;
  
}
.carouselContainer{
  display: block;
  height:500px;
}

.imageCarousel{
  width: 100%; 
  height:cover;
}

.interestsCarousel{
  width: 100%; 
  height:100%;
}

.aboutMe{
  width: 100%;
  font-weight: bold;
  margin-bottom: 20px;
}

.aboutMeParagraph{
  text-align: center;
  font-size: 15px;
  font-family: 'Bungee Inline', cursive;
  padding-top: 20px;
}

.aboutScreen-img-container{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  }

  #about-me-img{
    border-radius: 10px;
    width: 70%;
    }

#selfieImg{
  width: 100%;
  height: 100%;
  max-height: 300px;
}

.officeImgs{
  height:300px;
}

  .infoBody{
    display: block;
    height: 100%;
  }

  .interests{
    height: 100%;
    flex-direction: column;
    padding-top: 50px;
    font-size: 15px;
  }
  
  
  .interests-left{
    width:100%;
  }

  .interests-right{
    width:100%;
  }
  
  #interestTitle{
    font-size: 20px;
  }
  

  .interestList{ 
    font-family: 'Bungee Inline', cursive;
    text-align: center;
    font-size: 15px;
    list-style-type: none;
    
  }
  .interestList li{
    margin: 5px
  
  }
  #educationTitle{
    font-size: 20px;
  }
  


#projectTitle{
    font-size: 30px;
    padding-top: 70px;
    text-shadow: 2px 2px black;
}


  
.contactMe{
  color:white;
  padding-bottom: 20px;;
}

#contactMeTitle{
  color:white;
  font-size: 30px;
  margin-top: 30px;
  text-shadow: 2px 2px black;
}
.Footer{
    text-align: center;
}

  .videoContainer{
    padding-top: 10px;
    padding-bottom: 10px;
    width:350px;
  }
  
  .currentProjectTitle{
    font-size: 30px;
    padding-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 5px;

  }
  
  #contactLink{
    color:white;
    opacity: .9;
    }
    
    #contactLink:hover{
      opacity: 1;
      }
    
    #contactContainerSpacer{
      height: 50px;
    }
 
    
  

    
    .aboutScreen{
      height: 400px;
    }
    

    #projectsHeaderImageContainer{
      display: flex;
      align-items: center;
      padding-top: 50px;
      padding-bottom:50px;
      background-color: white;
    }

    #projectsHeaderImage{
      display: block;
      margin: auto;
      width:100%;
    }
    
    .currentNumbersContainer{
      display: flex;
      flex-wrap: wrap;
      margin-left: 10px;
      width: 100%;
      margin-bottom: 50px;
    }
    
    .currentNumber{      
      margin-right: 40px;
      text-align: center;
      width: 25%;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }
    
    .currentNumberTitle{
      margin-bottom: 25px;
      font-size: 15px;
      font-family: 'Bangers', cursive;
    }
    
    .countingNumber{
      font-size: 20px;
      text-shadow: none;
    }
    
    .currentNumberIcon{
      color: black
    }







.projectContainer{
  text-align: center;
  font-size: 15px;
  font-family: 'Bungee Inline', cursive;
}

.projectContainerFlexbox{
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin: auto
}

.projectContainerTitle{
 padding-top: 20px;
 padding-bottom: 20px;
 font-family: 'Bungee Shade', cursive;
 font-size: 20px;
}

.projectContainerHeader{
  font-family: 'Bungee Inline', cursive;
  font-size: 25px;
}

.projectImageSS{
  width: 100%;
  height: 300px;
  display: block;
  margin: auto;
  box-shadow: 2px 2px 11px black;

}

.projectImageSSContainer{
  width: 100%; 

}

.projectDescriptionContainer{
  width: 100%;
  text-align: center;
}

.accessContainer{
  text-align: left;
  margin: auto;
  width:120px;
}


.technologiesList{
  margin-bottom: 20px;
  margin-left: -50px;
}

.technologiesList li{
  display: block;
  position: relative;
  margin: 5px;
  font-size: 15px;
}

.projectContainerDescription{
  text-align: left;
}
}